import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import Login from "../../components/auth/";

const Index = () => {
  const authState = useSelector((state) => state.auth);
  useEffect(() => {
    if (
      authState.isAuthenticated === true &&
      authState.hasCheckedAuth === true
    ) {
      navigate("/");
    }
  }, [authState]);

  return (
    <div>
      <Login />
    </div>
  );
};

export default Index;
